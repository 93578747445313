import { Link,useParams } from 'react-router-dom';
import * as yup from 'yup';

import { ReactComponent as ArrowLinkIcon } from '@assets/icons/arrow-link-icon.svg';
import { NewLoader } from '@components/core/NewLoader';
import { getCategoriesDict } from '@helpers/categories';
import { isEmptyObject } from '@helpers/isEmptyObject';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useGetDisciplinesByIdQuery } from '@store/api/disciplinesApi';

import styles from '../SkillEditor.module.scss';
import EditSkillForm from './EditSkillForm';
import { useSkill } from './useSkill';

export const addSkillSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    content: yup.string().required('Поле обязательно для заполнения!'),
    picture: yup.mixed().label('File'),
    uuidsDiscipline: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    }))
});

const EditSkill = () => {

    const { skillUUID } = useParams();
    const { skill, picture, skillDisciplines } = useSkill(skillUUID as string);

    const { categories } = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000', {
        selectFromResult: ({ data: categoriesData }) => ({
            categories: categoriesData ?? []
        })
    });

    const realDisciplenesUuids = skillDisciplines?.map(discipline => discipline.uuidsRealDisciplines).flat() ?? [];
    const { data: disciplines } = useGetDisciplinesByIdQuery(realDisciplenesUuids);
    const realDisciplinesDict = disciplines?.reduce<{[uuid: string]: string}>((acc, discipline) => {
        acc[discipline.uuid] = discipline.uuidCategory;
        return acc;
    }, {});
    const categoriesDict = getCategoriesDict(categories);

    return (
        <div className={styles.page}>
            <Link to='../skills' className={styles.header}>
                <ArrowLinkIcon />
                <span>Редактировать компетенцию</span>
            </Link>


            {skill && skillDisciplines && !isEmptyObject(realDisciplinesDict) && !isEmptyObject(categoriesDict) ?
                <EditSkillForm
                    skillUUID={skillUUID as string}
                    skill={skill}
                    picture={picture}
                    skillDisciplines={skillDisciplines}
                    realDisciplinesDict={realDisciplinesDict!}
                    categoriesDict={categoriesDict}
                />
                :
                <NewLoader />
            }
        </div>
    );
};

export default EditSkill;
