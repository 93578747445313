import { toast } from 'react-toastify';

import { decodeUnicode, canParseJson } from '@helpers/base64';
import { initItems } from '@store/features/notifySlice';
import { NoticesResponse } from '@type/notifications';

import { emptyBaseApi } from './emptyBaseApi';


export const notificationsApi = emptyBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getNotices: build.query<NoticesResponse[], void>({
            //ToDo: Подгрузка сообщений по скроллу
            query() {
                return {
                    url: 'notice',
                    method: 'PUT',
                    body: {
                        limit: 10000,
                        offset: 0
                    }
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled;
                    const handledNewResultWithPayload = result.data.map(notice => ({
                        ...notice,
                        payload: canParseJson(decodeUnicode(notice.payload))
                    }));
                    dispatch(initItems(handledNewResultWithPayload));
                } catch (resError) {
                    // Костыль. Сейчас там есть ошибка, когда сервер переодически падает и приходит код 14. У бекендеров
                    // пока нет времени это исрпавить. По этому сделал так. Если эта ошибка пропадет в будущем, то нужно будет убрать костыль
                    // @ts-ignore
                    if(resError?.error?.data?.code!==14)
                        toast.error('Не смогли получить уведомления');
                }
            },
            transformResponse: (result: {notices: NoticesResponse[]}) => {
                return result.notices;
            },
            providesTags: ['Notices']
        }),
        markNotices: build.mutation<void, string[]>({
            query(data) {
                return {
                    url: 'notice/readed',
                    method: 'PUT',
                    body: {
                        baseUUID: data
                    }
                };
            },
            invalidatesTags: ['Notices']
        })
    })
});

export const { useMarkNoticesMutation, useLazyGetNoticesQuery, useGetNoticesQuery } = notificationsApi;
