import useWebSocket from 'react-use-websocket';
import { ReadyState } from 'react-use-websocket';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';

  
interface UseWebSocketReturnProps<T = any> extends Omit<WebSocketHook, 'lastJsonMessage'> {
    lastJsonMessage: T | null;
}

// Типизированный хук для вебсокета. Позволяет указать тип приходящий последний сообщений
export const useWebSocketWithTS = <T = any>(
    ...params: Parameters<typeof useWebSocket>
): [UseWebSocketReturnProps<T>,typeof ReadyState] => {

    const props: UseWebSocketReturnProps<T> = useWebSocket(...params);
    return [props, ReadyState];
};

