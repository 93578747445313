import { useEffect, useMemo, useRef, useState } from 'react';

import { Options, ReadyState } from 'react-use-websocket';

import { useWebSocketWithTS } from '@hooks/useWebSocketWithTS';
import { updateTokens } from '@store/baseQuery';
import { selectAccessToken, selectDeviceUuid, selectRefreshToken } from '@store/features/authSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

type Url = string | (() => string | Promise<string>) | null;

const SESSION_EXPIRED_CODE = 1011;

type MessageArgs = [jsonMessage: any, keep?: boolean];

export const useAuthWebSocket = <T = any>(url: Url, onConnect?: () => any) => {
    const dispatch = useAppDispatch();

    const accessToken = useAppSelector(selectAccessToken);
    const refreshToken = useAppSelector(selectRefreshToken);
    const deviceId = useAppSelector(selectDeviceUuid);

    const queueMessagesRef = useRef<MessageArgs[]>([]);

    const [isNeedConnect, setIsNeedConnect] = useState(false);

    const [messageKey, setMessageKey] = useState<string | null>(null);

    const handleRefreshToken = () => {
        updateTokens({
            token: accessToken,
            refresh: refreshToken,
            duuid: deviceId,
            dispatch
        });
    };

    const optionsMemo = useMemo<Options>(() => {
        return {
            shouldReconnect: (event) => {
                setIsNeedConnect(false);
                setMessageKey(null);

                if (event.code === SESSION_EXPIRED_CODE) {
                    handleRefreshToken();

                    return false;
                }
                return true;
            }
        };
    }, []);

    const [{ readyState, lastMessage, getWebSocket, sendJsonMessage: sendMessage, ...restProps }] = useWebSocketWithTS<T>(url, optionsMemo, isNeedConnect);

    useEffect(() => {
        if (lastMessage) {
            try {
                JSON.parse(lastMessage.data);
            } catch (e) {
                setMessageKey(lastMessage.data);
            }
        }
    }, [lastMessage]);
    const sendJsonMessage: typeof sendMessage = (...args) => {
        if (readyState === ReadyState.OPEN && messageKey) {
            sendMessage({
                ...args[0],
                key: messageKey
            }, args[1]);
        } else {
            queueMessagesRef.current.push(args);
        }
    };

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendMessage({
                type: 'auth',
                token: accessToken
            });

            if (queueMessagesRef.current.length > 0) {
                setTimeout(() => {
                    onConnect?.();
                }, 2000);
            } else {
                onConnect?.();
            }
        }
    }, [readyState]);

    useEffect(() => {
        if (readyState === ReadyState.OPEN && messageKey) {
            if (queueMessagesRef.current.length > 0) {
                queueMessagesRef.current.forEach((args) => {
                    sendMessage({
                        ...args[0],
                        key: messageKey
                    }, args[1]);
                });

                queueMessagesRef.current = [];
            }
        }
    }, [readyState, messageKey]);

    useEffect(() => {
        if (accessToken) {
            setIsNeedConnect(true);
        }
    }, [accessToken]);

    return { readyState, lastMessage, sendJsonMessage, ...restProps };
};
