import { FC, useEffect, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import IconUI from '@components/core/IconUI';
import Loader from '@components/core/Loader';
import { useAuthWebSocket } from '@hooks/useAuthWebSocket';
import { useSound } from '@hooks/useSound';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useGetNoticesQuery, notificationsApi, useMarkNoticesMutation } from '@store/api/notificationsApi';
import { notifyIsOpen, notifyItems, toggleNotify } from '@store/features/notifySlice';
import { selectApiUser } from '@store/features/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Notices } from '@type/notifications';

import style from './Notify.module.scss';
import { NotifyItem } from './NotifyItem';


const NOTICE_WS_URL = process.env.REACT_APP_NOTICE_WS_ENDPONT;


export const Notify: FC = () => {
    const [prevLengthItems,setLengthItems]=useState<null | number>(null);
    const items = useAppSelector(notifyItems);
    const toggle = useAppSelector(notifyIsOpen);
    const user = useAppSelector(selectApiUser);
    const { play } = useSound('notice');
    const dispatch = useAppDispatch();

    useGetNoticesQuery();
    const [readNotificationQuery] = useMarkNoticesMutation();

    const invalidateNotices = () => {
        dispatch(notificationsApi.util.invalidateTags(['Notices']));
    };

    const { lastJsonMessage: notifyLastJsonMessage , readyState: eventWsState, sendJsonMessage }  = useAuthWebSocket<Notices>(
        user ? `${NOTICE_WS_URL}/ws/${user.uuid}` : null,
        invalidateNotices
    );

    // Тут происходит проверка на то изменилось ли количество уведомлений с новым запросом
    // Если да, значит пришло новое уведомление, значит нужно включить уведмоление звуком.
    //  Это костыль на случай того пока не починять вебсокет уведомлений, что бы понимать когда включать звук
    useEffect(()=>{
        if(items.length>0){
            if(prevLengthItems===null){
                setLengthItems(items.length);
            }else{
                if(prevLengthItems<items.length  && items?.[0].readed===false){
                    play();
                    setLengthItems(items.length);
                }
            }
        }
    },[items,prevLengthItems]);

    useEffect(() => {
        if (notifyLastJsonMessage) {
            invalidateNotices();
        }
    }, [notifyLastJsonMessage]);

    const readNotification = (uuid: string) => {
        readNotificationQuery([uuid]);
    };

    const openHandle = () => {
        dispatch(toggleNotify(true));
    };

    const closeHandle = () => {
        dispatch(toggleNotify(false));
    };

    return (
        <>
            <SwipeableDrawer anchor="right" open={toggle} onClose={closeHandle} onOpen={openHandle}>
                <div className={style.notify}>
                    <div className={style.header}>
                        <div className={style.caption}>Уведомления</div>
                        <div className={style.close} onClick={closeHandle}>
                            <IconUI typeIcon='close' />
                        </div>
                    </div>

                    <div className={style.content}>
                        {items.length > 0 && (
                            <InfiniteScroll
                                dataLength={items.length}
                                next={() => null}
                                hasMore={false}
                                loader={<Loader />}
                                className={style.scroll}
                                scrollableTarget="scrollableDiv"
                            >
                                {items.map((item, index) => (
                                    <NotifyItem
                                        key={index}
                                        title={item.subject}
                                        date={item.publishBegin}
                                        type={item.noticeType}
                                        isReaded={item.readed}
                                        payload={item.payload}
                                        originData={item}
                                        readFunc={readNotification}
                                        onCloseDrawer={closeHandle}
                                    />
                                ))}
                            </InfiniteScroll>
                        )}

                        {!items.length && (
                            <div className={style.empty}>
                                <div className={style['icon-container']}>
                                    <IconUI typeIcon={'notify-bar'} />
                                </div>
                                Нет уведомлений
                            </div>
                        )}
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    );
};
