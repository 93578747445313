import { useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { ReactComponent as ArrowLinkIcon } from '@assets/icons/arrow-link-icon.svg';
import Button from '@components/core/inputs/Button';
import { FileUploadPhotoControl } from '@components/core/inputs/FileUpload/FileUploadPhotoControl';
import HelperText from '@components/core/inputs/HelperText/HelperText';
import InputControl from '@components/core/inputs/InputControl';
import { SelectStyled } from '@components/core/inputs/SelectStyled';
import Loader from '@components/core/Loader';
import TinyEditor from '@components/methodist/TinyEditor';
import { getBase64 } from '@helpers/base64';
import { getCategoriesDict } from '@helpers/categories';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateSkillRequestMessage, fileTypes } from '@store/api/apiTypes';
import { useGetCategoriesListQuery } from '@store/api/categoriesApi';
import { useGetDisciplinesByIdQuery } from '@store/api/disciplinesApi';
import { useCreateSkillMutation, useGetSkillDisciplinesQuery } from '@store/api/skillsApi';
import { useUploadFileMutation } from '@store/api/storageApi';

import styles from '../SkillEditor.module.scss';

export const addSkillSchema = yup.object({
    name: yup.string().required('Поле обязательно для заполнения!'),
    content: yup.string().required('Поле обязательно для заполнения!'),
    picture: yup.mixed().label('File'),
    uuidsDiscipline: yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required()
    })).required('Поле обязательно для заполнения!')
});

const AddSkill = () => {
    const navigate = useNavigate();

    const methods = useForm({
        resolver: yupResolver(addSkillSchema)
    });

    const { control, handleSubmit, formState } = methods;

    const {
        data: getSkillDisciplinesData,
        isLoading:getSkillDisciplinesIsLoading
    } = useGetSkillDisciplinesQuery(undefined);

    const [uploadFile, { isLoading: fileIsLoading }] = useUploadFileMutation();
    const [createSkill, { data: createSkillData, isLoading: createSkillIsLoading, isSuccess: createSkillIsSuccess }] = useCreateSkillMutation();
    const { categories } = useGetCategoriesListQuery('00000000-0000-0000-0000-000000000000', {
        selectFromResult: ({ data: categoriesData }) => ({
            categories: categoriesData ?? []
        })
    });

    const realDisciplenesUuids = getSkillDisciplinesData?.map(discipline => discipline.uuidsRealDisciplines).flat() ?? [];
    const { data: disciplines } = useGetDisciplinesByIdQuery(realDisciplenesUuids);
    const realDisciplinesDict = disciplines?.reduce<{[uuid: string]: string}>((acc, discipline) => {
        acc[discipline.uuid] = discipline.uuidCategory;
        return acc;
    }, {});
    const categoriesDict = getCategoriesDict(categories);

    useEffect(() => {
        if (createSkillIsSuccess) {
            toast.success('Компетенция добавлена');
            navigate(`../skills/edit/${createSkillData?.uuid}`);
        }
    }, [createSkillIsSuccess]);

    const onSubmit = async (formData: any) => {
        const transformedFormData: CreateSkillRequestMessage = {
            name:formData.name,
            content:formData.content,
            uuidsDiscipline: formData.uuidsDiscipline?.map((item:{label:string,value:string}) => item.value)
        };

        if (formData.picture) {
            const b64 = await getBase64(formData.picture);

            const uploadedFile = await uploadFile({
                file: b64 as string,
                meta: {
                    filename: formData.picture.name,
                    type: fileTypes.Public,
                    contentType: formData.picture.type
                }
            }).unwrap();

            transformedFormData.uuidPicture = uploadedFile.uuid;
        }

        createSkill(transformedFormData);
        navigate('../skills');
    };

    const cancelHandler = () => {
        navigate('../');
    };

    const options = getSkillDisciplinesData?.map((discipline) => ({
        label: discipline.name,
        value: discipline.uuid,
        parent: categoriesDict[realDisciplinesDict?.[discipline.uuidsRealDisciplines[0]] ?? '']
    })) ?? [];

    return (
        <div className={styles.page}>
            <Link to="../skills" className={styles.header}>
                <ArrowLinkIcon />
                <span>Добавить компетенцию</span>
            </Link>

            <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...methods}>
                    <div className={styles.content}>
                        <div className={styles.inputs}>
                            <p>Название*</p>
                            <InputControl name="name" />

                            <p>Описание*</p>
                            <div>
                                <TinyEditor name="content" control={control} error={formState.errors.content} />
                                {formState.errors.content &&
                                <HelperText message={formState.errors.content.message} />
                                }
                            </div>

                            <div className={styles.description}>
                                <p>Иконка</p>
                                <p className={styles.grayText}>
                                Форматы: PNG, SVG
                                    <br />
                                Размер: 120*120 px
                                </p>
                            </div>

                            <FileUploadPhotoControl name="picture" control={control}>
                                Добавить иконку
                            </FileUploadPhotoControl>

                            <p>Привязанные плановые дисциплины*</p>

                            {getSkillDisciplinesIsLoading ?
                                <Loader />
                                :
                                getSkillDisciplinesData &&
                                <SelectStyled
                                    control={control}
                                    name="uuidsDiscipline"
                                    options={options}
                                    isMulti
                                    isHierarchy
                                    showOptionHierarchy
                                    placeholder="Выберите плановые дисциплины"
                                />
                            }
                        </div>

                        <div className={styles.buttons}>
                            <Button type="submit" disabled={createSkillIsLoading || fileIsLoading} typeIcon={createSkillIsLoading ? 'loading' : undefined}>
                                Сохранить
                            </Button>
                            <Button onClick={cancelHandler} btnType="secondary">
                                Отмена
                            </Button>
                        </div>
                    </div>
                </FormProvider>
            </form>
        </div>
    );
};

export default AddSkill;
