import { OptionType } from '@components/core/inputs/SelectStyled/type';
import { ICategory } from '@store/api/apiTypes';

export const findCategory = (arr: ICategory[] | undefined, id: string | undefined): ICategory | undefined => {
    if (arr) {
        for (const obj of arr) {
            if (obj.uuid === id) return obj;
            const nestedObj = findCategory(obj.children, id);
            if (nestedObj) return nestedObj;
        }
    }
};

export const getCategoriesDict = (categories: ICategory[]): { [uuid: string]: string } => {
    const result: { [uuid: string]: string } = {};

    const unwrapCategories = (category: ICategory) => {
        result[category.uuid] = category.name;
        if (category.children) {
            for (const child of category.children) {
                unwrapCategories(child);
            }
        }
    };

    for (const category of categories) {
        unwrapCategories(category);
    }

    return result;
};

export const flatCategoriesToOptions = (categories: ICategory[]): OptionType[] => {
    const result: OptionType[] = [];
    for (const category of categories) {
        result.push({ label: category.name, value: category.uuid });
        if (category.children) {
            result.push(...flatCategoriesToOptions(category.children));
        }
    }
    return result;
};
