import cx from 'classnames';

import { Column, Grid } from '@components/core/grid';
import { MenuItem, TableMenu } from '@components/Menu';
import { useToggle } from '@hooks/useToggle.hook';
import { SessionTypesWithLocale, SkillDiscipline, WithUUID } from '@store/api/apiTypes';
import { useDeleteSkillDisciplineMutation } from '@store/api/skillsApi';

import UpdateSkillDisciplineModal from '../../UpdateSkillDisciplineModal';
import styles from './DisciplinesTable.module.scss';

const DisciplinesTableRow = ({ discipline }: { discipline: WithUUID<SkillDiscipline>}) => {

    const [deleteDiscipline] = useDeleteSkillDisciplineMutation();

    const [isOpen, { on, off }] = useToggle(false);

    return (
        <>
            <Grid gridTemplateColumns="1fr 1fr" className={cx('row--selectable', styles.row)} key={discipline.uuid} onClick={on}>
                <Column>{discipline.name}</Column>
                <Column>
                    {SessionTypesWithLocale[discipline.semester as keyof typeof SessionTypesWithLocale]}
                    <TableMenu  className={styles.menu} onClick={(e)=>{e.stopPropagation();}}>
                        <MenuItem onClick={on}>Редактировать</MenuItem>
                        <MenuItem danger onClick={()=>{deleteDiscipline(discipline.uuid);}}>Удалить</MenuItem>
                    </TableMenu>
                </Column>
            </Grid>
            <UpdateSkillDisciplineModal isOpen={isOpen} closeModal={off} skillDiscipline={discipline} />
        </>
    );
};

export default DisciplinesTableRow;